/* eslint-disable */
import { mapGetters, mapActions } from "vuex"
import { ApiReporting } from '@/api-routes.js'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import SectionEmpty from '@/views/partials/SectionEmpty'

export default {
  props: ['slug', 'color'],
  components: {
    HeroBar,
    CardComponent,
    'app-section-empty': SectionEmpty
  },

  data() {
    return {
      title: 'Report',
      endpoint: ApiReporting
    }
  },

  computed: {
    ...mapGetters([
      'item',
      "loading",
      "error"
    ])
  },

  methods: {
    ...mapActions([
      "setError",
      "fetchItem",
    ]),

    goBack() {
      this.$router.go(-1);
    }
  },

  created() {
    // console.log('reporting mixin created slug: ', this.slug);
  }

};

