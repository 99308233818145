<template>
  <div>
    <hero-bar>
      {{ title }}
      <router-link slot="right" to="/reporting" class="button">
        {{ $getTranslation('general.views.reporting') }}
      </router-link>
      <router-link slot="right" :to="{ name: 'reporting.pdf-list', params: { slug: slug } }" class="button is-active" style="margin-left: 10px">
        PDF list
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <div v-if="error"> {{ $getTranslation('general.message.generalError') }} </div>
      <button v-if="!loading" @click="goBack">Go back</button>
      <div v-else>{{ $getTranslation('general.loading') }}</div>
    </section>
  </div>
</template>

<script>
import ReportingTemplateMixin from '@/mixins/ReportingTemplateMixin'
import { mapActions } from "vuex"
import { ApiReporting } from '@/api-routes.js'

export default {
  name: 'app-report',
  props: ['slug', 'level', 'type', 'color'],
  mixins: [ReportingTemplateMixin],
  data() {
    return {
      // title: 'PDF Export'
      title: 'PDF ' +this.$getTranslation('form.general.export')
    }
  },

  methods: {
    ...mapActions([
      "downloadItem",
      "downloadItems"
    ]),
  },

  created() {
    let payload = {}
    // this.title = this.$route.meta.routeName

    if(typeof this.level === 'undefined' && typeof this.type === 'undefined') {
      let endpoint = this.endpoint + '/pdfZip/' + this.slug + '/' + this.color;
      // console.log('pdf-export download created: ', this.slug, endpoint )
      payload = {
        endpoint: endpoint,
      }
      this.downloadItems(payload)
    } else {
      let endpoint = this.endpoint + '/pdfExport/' + this.slug + '/' + this.level + '/' + this.type + '/' + this.color;
      // console.log('pdf-export created: ', this.slug, endpoint )
      payload = {
        endpoint: endpoint,
      }
      this.downloadItem(payload)
    }

  }
}
</script>
